<template>
  <modal class="modal-form" @exit="$emit('exit')">
    <template v-slot:header>
      <span v-if="position.id">Edycja środka <strong>{{ position.name }}</strong></span>
      <span v-else>Nowy środek <strong>{{ position.name }}</strong></span>
    </template>
    <template v-slot:body>
      <form @submit.stop.prevent="save" class="form-table">
        <div class="field-row">
          <div class="form-group name">
            <label>Nazwa</label>
            <div class="form-text">
              <input-wrapper :error="errors.name">
                {{ position.name }}
              </input-wrapper>
            </div>
          </div>
          <div class="form-group groups">
            <label>Zastosowanie</label>
            <div class="form-text">
              <input-wrapper :error="errors.groups">
                <template v-for="g in position.groups">
                  {{ g.name }}
                </template>
              </input-wrapper>
            </div>
          </div>
          <div class="form-group quantity-group">
            <label>Stan</label>
            <div class="form-control">
              <div class="simple-row">
                <input-wrapper :error="errors.stockQuantity">
                  <input type="number" lang="pl" class="text" :disabled="!hasRole('ROLE_PLANT_PROTECTION_MANAGER')"
                         step="0.01" v-model="position.stockQuantity" ref="first"/>
                </input-wrapper>
                <select v-model="position.stockUnit" class="auto-width"
                        :disabled="!hasRole('ROLE_PLANT_PROTECTION_MANAGER')">
                  <option v-for="(name, unit) in units" :key="unit" :value="unit">{{ name }}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="form-group groups">
            <label>Opis</label>
            <div class="form-control">
              <textarea-autosize v-model="position.description"
                                 :disabled="!hasRole('ROLE_PLANT_PROTECTION_MANAGER')"/>
            </div>
          </div>

        </div>
        <confirm v-if="remove" @close="remove=false" @ok="onRemove">
          Z Twojej listy zostanie usunięty środek <strong>{{ position.name }}</strong>
        </confirm>
      </form>
    </template>
    <template v-slot:footer>
      <div class="btn-bar">
        <div class="left form-table">
          <print/>
          <div class="field-row">
            <div class="form-group simple-group">
              <label>Dostępność</label>
              <div class="form-control">
                <label><input type="checkbox" :disabled="!hasRole('ROLE_PLANT_PROTECTION_MANAGER')" name="check"
                              id="position-enabled" v-model="position.enabled"/> włącz</label>
              </div>
            </div>
          </div>
        </div>
        <div class="right">
          <button type="button" v-if="hasRole('ROLE_PLANT_PROTECTION_MANAGER')" @click="save" class="button">zapisz <i
              class="ico ico-save"></i></button>
          <button type="button" @click.prevent="$emit('exit')" class="button">anuluj</button>
          <button type="button" v-if="hasRole('ROLE_PLANT_PROTECTION_MANAGER') && model && model.id"
                  @click.prevent="remove = true" class="button button-danger">usuń <i class="ico ico-remove"></i>
          </button>
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
import InputWrapper from '../../util/Input';
import Units from '@/service/unit.js'
import Print from '@/components/util/Print'
import { mapActions } from "vuex";
import mixturesApi from '@/api/mixtures'

export default {
  props: { model: null },
  components: { InputWrapper, Print },
  data() {
    const tmp = {
      name: '',
      quantity: null,
      description: '',
      stockUnit: 'l'
    };
    return {
      position: { ...tmp, ...this.model },
      errors: {},
      remove: false,
      units: Units.getAll()
    }
  },
  mounted() {
    this.$bus.$emit('focus', this.$refs.first);
  },
  methods: {
    ...mapActions('auth', ['hasRole']),
    onRemove() {
      mixturesApi.deletePlantProtection(this.model.id).then(() => {
        this.$notify({
          title: this.$t('saved'),
          type: 'success'
        });
        this.$emit('refresh');
        this.$emit('close');
      });
    },
    save() {
      if (!this.hasRole('ROLE_PLANT_PROTECTION_MANAGER')) {
        return
      }
      const payload = {
        groupIds: this.position.groups.map(g => g.id),
        ingredientIds: this.position.ingredients.map(i => i.id),
        ...this.position,
      }
      delete payload.groups;
      delete payload.ingredients;
      mixturesApi.creatPlantProtection(payload).then(() => {
        this.$emit('refresh');
        this.errors = {};
        this.$notify({
          title: this.$t('saved'),
          type: 'success'
        });
        this.$emit('saved', this.position);
        this.$emit('refresh');
      }).catch((err) => {
        if (err.response && err.response.status === 422) {
          this.errors = this.$errors.transform(err.response.data)
          this.$notify({
            title: this.$t('error.form'),
            type: 'error'
          })

        } else {
          this.$notify({
            title: this.$t('error.connection'),
            type: 'error'
          })
        }
      });
    }
  }
}
</script>

<style scoped>
input[type=text], input[type=number], select {
  width: 100%;
}

.quantity-group select, .quantity-group input {
  width: 100px;
}
</style>
