<template>
  <div>
    <h1>{{ breadcrumb() }}</h1>
    <div class="edit-table">
      <div class="btn-bar">
        <div class="left">
          <a v-if="hasRole('ROLE_PLANT_SEASON_MANAGER')" href="#" @click.prevent="create = true" class="button">dodaj
            nowy <i class="ico ico-add"></i></a>
        </div>
        <div class="right">
          <search v-model="search" @filter="filter()" :print="true"/>
        </div>
      </div>
      <div class="line"></div>
      <div v-if="create" class="new">
        <h2>Nowa definicja</h2>
        <model-form @refresh="load()" @exit="create = false" @created="create = false"/>
        <div class="line"></div>
      </div>
      <div class="grid">
        <div class="head">
          <div class="name">Nazwa</div>
          <div class="av">Udostępnienie</div>
        </div>
        <template v-if="grid.collection.length > 0">
          <template v-for="(position, i) in grid.collection">
            <model-form v-if="position.edit" @refresh="load()" @saved="saved(i, position, $event)" :model="position"
                        @exit="position.edit = false"></model-form>
            <div @click.prevent="position.edit = true" class="row" v-else>
              <div class="name">
                <text-highlight :queries="highlight">{{ position.name }}</text-highlight>
              </div>
              <div class="av center btn-cell">
                <enabled :value="position.enabled"/>
                <a href="#"><i class="icon-edit"></i></a>
              </div>
            </div>
          </template>
        </template>
        <div v-else class="row">
          <div class="empty">
            <span v-if="$route.query.search">
              Nic nie znaleziono.
              <button
                  type="button"
                  class="button"
                  @click.prevent="clearFilter()"
              >
                usuń filtr
              </button>
            </span>
            <span v-else>Lista jest pusta</span>
          </div>
        </div>
      </div>
      <paginator :pages="grid.pageCount" :active="grid.page" @click="onPage"/>
    </div>
  </div>
</template>

<script>
import ModelForm from './form/Season'
import TextHighlight from 'vue-text-highlight'
import Paginator from '../util/Paginator'
import Search from '../util/Search'
import { mapActions } from "vuex";
import seasonsApi from '@/api/seasons'

export default {
  components: { ModelForm, TextHighlight, Paginator, Search },
  created() {
    this.load();
  },
  data() {
    return {
      create: false,
      search: this.$route.query.search,
      grid: {
        collection: [],
        pagination: {
          itemsLength: 0,
          page: 1,
          pageCount: 1
        }
      }
    }
  },
  watch: {
    $route() {
      this.search = this.$route.query.search;
      this.load();
    }
  },
  computed: {
    highlight() {
      if (typeof this.search != 'string') {
        return '';
      }
      return this.search;
    }
  },
  methods: {
    ...mapActions('auth', ['hasRole']),
    clearFilter() {
      this.search = null;
      this.filter();
    },
    onPage(page) {
      this.updateQuery({
        page: page
      })
    },
    filter() {
      this.updateQuery({
        search: this.search,
        page: 1
      })
    },
    updateQuery(params) {
      let query = Object.assign({}, this.$route.query, params);
      if (query.page === 1) {
        delete query.page;
      }
      if (!query.search) {
        delete query.search;
      }
      this.$router.push({ query: query }).catch(() => {
        this.load();
      });
    },
    load() {
      seasonsApi.getSeasons({
        search: this.$route.query.search,
        page: this.$route.query.page || 1,
        archive: true
      }).then((resp) => {
        resp.data.collection.forEach(function (item) {
          item.edit = false;
        })
        this.grid = resp.data;
      });
    },
    breadcrumb() {
      return 'Sezony';
    },
    saved(i, old, item) {
      old.edit = false;
      this.grid.collection[i] = { ...item };
      this.grid.collection[i].edit = false;
    }
  },
}
</script>

<style scoped>
div.name {
  flex: 1;
}

div.av {
  width: 90px;
}
</style>
