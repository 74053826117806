<template>
    <div class="grids">
        <usermenu />
        <div class="content">
           <div class="form">
             <p v-show="error" class="error">{{ error }}</p>
             <form @submit.prevent="save">
                 <div class="input-group">
                    <label for="current-password" class="input-label">{{ $t('change_password.current_password') }}</label>
                    <input type="password" v-model="currentPassword" class="input-form" :placeholder="$t('change_password.current_password')" />
                 </div>
                 <div class="input-group">
                    <label for="new-password" class="input-label">{{$t('change_password.new_password')}}</label>
                    <input type="password" v-model="newPassword" class="input-form" :placeholder="$t('change_password.new_password')" />
                 </div>
                 <div class="input-group">
                    <label for="repeat-password" class="input-label">{{$t('change_password.repeat_password')}}</label>
                    <input type="password" v-model="repeatPassword" class="input-form" :placeholder="$t('change_password.repeat_password')" />
                 </div>
                 <div class="foot">
                    <input type="submit" class="button" :value="$t('save')" />
                 </div>
             </form>
           </div>
        </div>
    </div>
</template>
 
<script>
import usermenu from './UserMenu'
export default {
    components : {usermenu},
    data() {
        return {
            currentPassword: null,
            newPassword: null,
            repeatPassword : null,
            error : null
        }
    },
    methods : {
        save() {
            if (!this.currentPassword) {
                this.error = this.$t('change_password.error.current_not_empty');
                return;
            }
            if (!this.newPassword) {
                this.error = this.$t('change_password.error.not_empty');
                return;
            }
            if (this.currentPassword == this.newPassword) {
                this.error = this.$t('change_password.error.not_equal');
                return;
            }
            if (this.repeatPassword != this.newPassword) {
                this.error = this.$t('change_password.error.repeat');
                return;
            }
            this.$http.post('user/change-password', {
                currentPassword : this.currentPassword,
                newPassword : this.newPassword
            }).then((req) => {
                if (!req.data.success) {
                    this.error = req.data.error
                } else {
                    this.error = this.$t('saved');
                }
            });
        },
        breadcrumb() {
            return this.$t('change_password.title');
        }
    }
}
</script>
 <style scoped>
.error {
    font-weight: bold;
    text-align: center;
    padding: 10px;
}

.form {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
}

.input-group {
    margin-bottom: 20px;
}

.input-label {
    display: block;
    margin-bottom: 8px;
    font-weight: 500;
}

.input-form {
    width: 100%;
    padding: 8px 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
}

.foot {
    margin-top: 30px;
    text-align: right;
}

.button {
    padding: 8px 24px;
    border-radius: 4px;
    cursor: pointer;
}
</style>
