<template>
  <modal class="modal-form" @exit="$emit('exit')">
    <template v-slot:header>
      <span v-if="position.id">Edycja mieszaniny <strong>{{ position.name }}</strong></span>
      <span v-else>Nowa mieszanina</span>
    </template>
    <template v-slot:body>
      <FertilizerList v-if="isFertilizerListVisible" ref="fertilizers" :params="listParams" :modal="true"
                      @rowclick="createFertilizerPosition" :route="false" :exclude="fertilizerIds">
        <template v-slot:header>
          <h2>Wybierz swój nawóz</h2>
        </template>
        <template v-slot:default>
          <archive-button v-model="listParams.archive" @input="$refs.fertilizers.load()"/>
        </template>
        <template v-slot:footer>
          <a href="#" @click.stop="isFertilizerListVisible = false" class="button">anuluj <i class="ico ico-close"></i></a>
        </template>
      </FertilizerList>
      <PlantProtectionList v-if="isPlanProtectionListVisible" ref="plantProtections" :params="listParams" :modal="true"
                           @rowclick="createPlantProtectionPosition" :route="false" :exclude="plantProtectionIds">
        <template v-slot:header>
          <h2>Wybierz swój środek</h2>
        </template>
        <template v-slot:default>
          <archive-button v-model="listParams.archive" @input="$refs.plantProtections.load()"/>
        </template>
        <template v-slot:footer>
          <a href="#" @click.stop="isPlanProtectionListVisible = false" class="button">
            anuluj
            <i class="ico ico-close"/>
          </a>
        </template>
      </PlantProtectionList>
      <form @submit.stop.prevent="save" class="form-table">
        <div class="field-row">
          <div class="form-group name">
            <label>Nazwa</label>
            <div class="form-control">
              <input-wrapper :error="errors.name">
                <input
                    type="text"
                    class="text"
                    :disabled="!hasRole('ROLE_MIXTURE_MANAGER')"
                    v-model="position.name"
                    ref="first"
                />
              </input-wrapper>
            </div>
          </div>
          <div class="form-group description">
            <label>Zastosowanie</label>
            <div class="form-control">
              <textarea-autosize
                  v-model="position.description"
                  :disabled="!hasRole('ROLE_MIXTURE_MANAGER')"
              />
            </div>
          </div>
          <div class="form-group performance">
            <label v-tooltip="'Wydajność opryskiwacza [ha]'">Na opryskiwacz[ha]</label>
            <div class="form-control">
              <input-wrapper :error="errors.surface">
                <input type="number" lang="pl" class="text" step="0.01" v-model="position.surface"
                       :disabled="!hasRole('ROLE_MIXTURE_MANAGER')"/>
              </input-wrapper>
            </div>
          </div>
          <div class="form-group date">
            <label>Data utworzenia</label>
            <div class="form-control">
              <input-wrapper :error="errors.creationDate">
                <datepicker v-model="position.creationDate" :disabled="!hasRole('ROLE_MIXTURE_MANAGER')"
                            valueType="YYYY-MM-DD" :clearable="false" format="DD.MM.YYYY"/>
              </input-wrapper>
            </div>
          </div>
        </div>
        <div class="grid positions" v-if="position.positions.length > 0">
          <div class="head">
            <div class="name">Nazwa środka</div>
            <div class="quantity">Ilość na [ha]</div>
            <div class="quantity">Na opryskiwacz</div>
            <div class="content">Skład</div>
            <div class="description"></div>
          </div>
          <div class="row" v-for="(row, index) in position.positions" :key="'pos'+index">
            <div class="name">
              <input-wrapper :error="errors['positions[' + index + '].plantProtection']" v-if="row.plantProtection">
                {{ row.plantProtection.name }}
              </input-wrapper>
              <input-wrapper :error="errors['positions[' + index + '].fertilizer']" v-if="row.fertilizer">
                {{ row.fertilizer.name }}
              </input-wrapper>
            </div>
            <div class="quantity">
              <span class="simple-row">
                <input-wrapper :error="errors['positions[' + index + '].quantity']">
                  <field-unit
                      :disabled="!hasRole('ROLE_MIXTURE_MANAGER')"
                      v-model="row.quantity"
                      :name="'qty' + index"
                      :unit="row.plantProtection ? row.plantProtection.unit : row.fertilizer.unit"
                  />
                </input-wrapper>
                <span v-if="row.plantProtection" class="input-value">
                  <unit :unit="row.plantProtection.unit" :value="false"/>
                </span>
                <span v-if="row.fertilizer" class="input-value">
                  <unit :unit="row.fertilizer.unit" :value="false"/>
                </span>
              </span>
            </div>
            <div class="quantity">
              <span class="simple-row num">
                <input-wrapper :error="errors['positions[' + index + '].ignore']">
                  <unit
                      :unit="row.plantProtection ? row.plantProtection.unit : row.fertilizer.unit"
                      :value="row.quantity * position.surface"
                  />
                </input-wrapper>
              </span>
            </div>
            <div class="content">
              <template v-if="row.fertilizer">
                <span v-if="row.fertilizer.composition.n">
                  <strong>N</strong>
                  <span class="input-value">
                    {{ $n(row.fertilizer.composition.n, 'simple') }}
                  </span>
                </span>
                <span v-if="row.fertilizer.composition.p">
                  <strong>P<sub>2</sub>O<sub>5</sub></strong>
                  <span class="input-value">
                    {{ $n(row.fertilizer.composition.p, 'simple') }}
                  </span>
                </span>
                <span v-if="row.fertilizer.composition.b">
                  <strong>K<sub>2</sub>O</strong>
                  <span class="input-value">
                    {{ $n(row.fertilizer.composition.b, 'simple') }}
                  </span>
                </span>
                <span v-if="row.fertilizer.composition.ca">
                  <strong>CaO</strong>
                  <span class="input-value">
                    {{ $n(row.fertilizer.composition.ca, 'simple') }}
                  </span>
                </span>
                <span v-if="row.fertilizer.composition.mg">
                  <strong>MgO</strong>
                  <span class="input-value">
                    {{ $n(row.fertilizer.composition.mg, 'simple') }}
                  </span>
                </span>
              </template>
              <template v-if="row.plantProtection">
                <div class="composition">
                  <span v-for="(cnt, ii) in row.plantProtection.contents" v-bind:key="ii">
                    <strong>{{ cnt.ingridient.name }}</strong>
                    -
                    <unit :value="cnt.quantity" :unit="cnt.unit"/>
                    {{ ii < row.plantProtection.contents.length - 1 ? ', ' : '' }}
                  </span>
                </div>
              </template>
            </div>
            <div class="description align-right">
              <button
                  v-if="hasRole('ROLE_MIXTURE_MANAGER')"
                  type="button"
                  @click.prevent="removePosition = row"
                  class="button button-danger"
              >
                usuń
                <i class="ico ico-remove"/>
              </button>
            </div>
          </div>
        </div>
        <div class="btn-bar">
          <div class="left"></div>
          <div class="right">

          </div>
        </div>
        <confirm v-if="remove" @close="remove=false" @ok="onRemove">
          Zostanie usunięta mieszanina <strong class="nowrap">{{ position.name }}</strong>
        </confirm>
        <confirm v-if="removePosition" @close="removePosition=false" @ok="onRemovePosition">
          <template v-if="removePosition.plantProtection">
            Z mieszaniny zostanie usunięty środek
            <strong class="nowrap">{{ removePosition.plantProtection.name }}</strong>
          </template>
          <template v-if="removePosition.fertilizer">
            Z mieszaniny zostanie usunięty nawóz <strong class="nowrap">{{ removePosition.fertilizer.name }}</strong>
          </template>
        </confirm>
      </form>
    </template>
    <template v-slot:footer>
      <div class="btn-bar">
        <div class="left form-table">
          <print/>
          <div class="field-row">
            <div class="form-group simple-group">
              <label>Dostępność</label>
              <div class="form-control">
                <label>
                  <input
                      type="checkbox"
                      :disabled="!hasRole('ROLE_MIXTURE_MANAGER')"
                      name="check"
                      id="position-enabled"
                      v-model="position.enabled"
                  />
                  włącz
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="right">
          <button type="submit" v-if="hasRole('ROLE_MIXTURE_MANAGER')" @click.prevent="save" class="button">
            zapisz
            <i class="ico ico-save"/>
          </button>
          <button type="button" @click.prevent="$emit('exit')" class="button">
            anuluj
          </button>
          <button type="button" @click.prevent="$emit('duplicate', position)" class="button"
                  v-if="hasRole('ROLE_MIXTURE_MANAGER') && model && model.id > 0">
            powiel
            <i class="ico ico-add"/>
          </button>
          <button type="button" v-if="hasRole('ROLE_MIXTURE_MANAGER') && model && model.id"
                  @click.prevent="remove = true" class="button button-danger">
            usuń
            <i class="ico ico-remove"/>
          </button>
          <button type="button" v-if="hasRole('ROLE_MIXTURE_MANAGER')" class="button"
                  @click="isPlanProtectionListVisible=true">
            dodaj środek
            <i class="ico ico-add"/>
          </button>
          <button type="button" v-if="hasRole('ROLE_MIXTURE_MANAGER')" class="button"
                  @click="isFertilizerListVisible=true">
            dodaj nawóz
            <i class="ico ico-add"/>
          </button>
        </div>
      </div>
      <loader style="float: left" v-show="load > 0" color="#0065a2" class="loader"/>
    </template>
  </modal>
</template>
<script>
import InputWrapper from '@/components/util/Input'
import Modal from '@/components/util/Modal'
import FertilizerList from '@/components/agro/list/Fertilizer'
import PlantProtectionList from '@/components/agro/list/PlantProtection'
import loader from '@/components/util/Loader'
import FieldUnit from '@/components/widget/FieldUnit'
import Unit from '@/components/widget/Unit'
import Print from '@/components/util/Print'
import moment from 'moment'
import { mapActions } from "vuex";
import mixturesApi from '@/api/mixtures'

let previous = {};
export default {
  props: { model: null, lastSurface: null },
  components: { InputWrapper, Print, Modal, loader, FertilizerList, PlantProtectionList, FieldUnit, Unit },
  data() {
    let tmp = {
      name: '',
      surface: this.lastSurface || 0,
      description: '',
      creationDate: moment().format(),
      positions: [],
      enabled: true
    };
    const adjustedModel = this.model.name ? {
      ...this.model,
      positions: this.model.plantProtections.map((p) => {
        return {
          plantProtection: p.plantProtection,
          quantity: p.quantity,
          unit: p.quantityUnit
        }
      }).concat(this.model.fertilizers.map((f) => {
        return {
          fertilizer: f.fertilizer,
          quantity: f.quantity,
          unit: f.quantityUnit
        }
      }))
    } : {};
    previous = { ...tmp, ...adjustedModel };
    return {
      position: previous,
      errors: {},
      remove: false,
      isPlanProtectionListVisible: false,
      isFertilizerListVisible: false,
      removePosition: false,
      load: 0,
      listParams: {
        own: true,
        archive: true
      }
    }
  },
  computed: {
    plantProtectionIds() {
      let ids = []
      this.position.positions.forEach((p) => {
        if (p.plantProtection) {
          ids.push(p.plantProtection.id)
        }
      })
      return ids
    },
    fertilizerIds() {
      let ids = []
      this.position.positions.forEach((p) => {
        if (p.fertilizer) {
          ids.push(p.fertilizer.id)
        }
      })
      return ids
    },
  },
  created() {
    this.$bus.$on('load', this.onLoad);
  },
  mounted() {
    this.$nextTick(() => {
      this.$bus.$emit('focus', this.$refs.first)
    })
  },
  destroyed() {
    this.$bus.$off('load', this.onLoad);
  },
  methods: {
    ...mapActions('auth', ['hasRole']),
    onLoad(state) {
      this.load += state ? 1 : -1;
    },
    createPlantProtectionPosition(plantProtection) {
      this.position.positions.push({
        plantProtection: plantProtection,
        quantity: null,
        unit: plantProtection.unit || 'l'
      });

      this.isPlanProtectionListVisible = false
      this.$nextTick(() => {
        this.$el.querySelector('[name=qty' + (this.position.positions.length - 1) + ']').focus()
      });
    },
    createFertilizerPosition(fertilizer) {
      this.position.positions.push({ fertilizer: fertilizer, quantity: null, unit: fertilizer.unit });

      this.isFertilizerListVisible = false
      this.$nextTick(() => {
        this.$el.querySelector('[name=qty' + (this.position.positions.length - 1) + ']').focus()
      });
    },
    onRemovePosition() {
      this.position.positions = this.position.positions.filter((row) => {
        return row !== this.removePosition
      })
      this.removePosition = false
    },
    onRemove() {
      mixturesApi.deleteMixture(this.model.id).then(() => {
        this.$notify({
          title: this.$t('saved'),
          type: 'success'
        });
        this.$emit('refresh');
        this.$emit('exit');
        previous = null
      });
    },
    save() {
      if (!this.hasRole('ROLE_MIXTURE_MANAGER')) {
        return
      }
      previous = null;
      let promise = null;
      this.position.plantProtections = [];
      this.position.fertilizers = [];

      if (this.position.positions && this.position.positions.length !== 0) {
        this.position.positions.forEach((row) => {
          if (row.plantProtection) {
            this.position.plantProtections.push({
              plantProtectionId: row.plantProtection.id,
              quantity: row.quantity
            })
          }
          if (row.fertilizer) {
            this.position.fertilizers.push({
              fertilizerId: row.fertilizer.id,
              quantity: row.quantity
            })
          }
        });
      }

      delete this.position.positions;

      if (this.position && this.position.id) {
        promise = mixturesApi.updateMixture(this.position.id, this.position);
      } else {
        promise = mixturesApi.createMixture(this.position);
      }

      promise
          .then(() => {
            this.$emit('refresh');
            this.errors = {};
            this.$notify({
              title: this.$t('saved'),
              type: 'success'
            });
            if (this.model && this.model.id) {
              this.$emit('saved', this.position);
            } else {
              this.$emit('created', this.position);
            }
          })
          .catch((err) => {
            if (err.response && err.response.status === 422) {
              this.errors = this.$errors.transform(err.response.data)
              this.$notify({
                title: this.$t('error.form'),
                type: 'error'
              })

            } else {
              this.$notify({
                title: this.$t('error.connection'),
                type: 'error'
              })
            }
          })
    }
  },
}
</script>
<style scoped lang="scss">
.content {
  width: 400px;
}

.row .content {
  font-size: 12px;
  line-height: 1.2em;
}

input[type=text], input[type=number], select {
  width: 100%;
}

.positions .quantity {
  width: 120px;

  .simple-row > span:first-child {
    flex: 1;
  }

  .simple-row > span:last-child {
    width: 20px;
  }
}

.positions .name {
  width: 250px;
}

div.date input {
  width: 80px;
}

.field-row {
  div.name input {
    width: 250px;
  }

  div.performance input, div.date .vdp-datepicker {
    width: 80px;
  }

  div.description textarea {
    flex: 1
  }
}
</style>
