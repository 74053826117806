<template>
  <modal :class="modal ? 'modal-grid' : 'content-grid'" :enabled="modal">
    <template v-slot:header>
      <slot name="header"></slot>
      <div class="btn-bar">
        <div class="left">
          <slot></slot>
        </div>
        <div class="right">
          <search v-model="query.search" @filter="filter()" ref="search" :print="!modal"/>
        </div>
      </div>
    </template>
    <template v-slot:body>
      <div class="grid">
        <div class="head">
          <div class="name">Nazwa</div>
          <div class="usage">Zastosowanie</div>
          <div class="quantity num">Stan</div>
          <div class="description">Opis</div>
          <div class="composition">Skład</div>
          <div class="av" v-if="av">Udostępnienie</div>
        </div>
        <div class="scrollable" v-if="grid.collection.length > 0">
          <template v-for="(position, i) in grid.collection">
            <div :class="'row' + (isExcluded(position) ? ' disabled' : '')" @click.prevent="rowClick(position)"
                 v-bind:key="i">
              <div class="name">
                <text-highlight :queries="highlight">{{ position.name }}</text-highlight>
              </div>
              <div class="usage">
                <text-highlight :queries="highlight">
                  <template v-for="group in position.groups">
                    {{ group.name }}
                  </template>
                </text-highlight>
              </div>
              <div class="quantity num">
                <unit :value="position.stockQuantity" :unit="position.stockUnit"/>
              </div>
              <div class="description">
                <description :queries="highlight" :value="position.description"/>
              </div>
              <div class="composition">
                <ul class="small-list">
                  <li v-for="(ingredient, index) in position.ingredients" v-bind:key="index">
                    <text-highlight :queries="highlight">{{ ingredient.name }}</text-highlight>
                  </li>
                </ul>
              </div>
              <div class="av center btn-cell" v-if="av">
                <enabled :value="position.enabled"/>
                <a href="#"><i class="icon-edit"></i></a>
              </div>
            </div>
          </template>
        </div>
        <div class="row" v-else>
          <div class="empty">
                        <span v-if="grid.params.search">
                            Nic nie znaleziono. <button type="button" class="button" @click.prevent="clearFilter()">usuń filtr</button>
                            <span v-if="grid.sub && grid.sub.total > 0">
                                <br/>Pozycja jest moich środkach <span
                                v-if="!grid.sub.data[0].enabled">jako niedostępna</span>: <em>{{
                                grid.sub.data[0].name
                              }}</em>
                            </span>
                        </span>
            <span v-else>Lista jest pusta. <strong>Sprawdź niedostępne.</strong></span>
          </div>
        </div>

      </div>
    </template>
    <template v-slot:footer>
      <div class="btn-bar">
        <div class="left">
          <paginator :pages="grid.pagination.pageCount" :active="grid.pagination.page" @click="onPage"/>
        </div>
        <div class="right">
          <slot name="footer"/>
        </div>
      </div>

    </template>
  </modal>
</template>
<script>
import TextHighlight from 'vue-text-highlight'
import Paginator from '@/components/util/Paginator'
import Search from '@/components/util/Search'
import Unit from '@/components/widget/Unit'
import mixturesApi from '@/api/mixtures'

export default {
  components: { TextHighlight, Paginator, Search, Unit },
  name: 'plant-protection-list',
  props: {
    av: {
      type: Boolean,
      default: true
    },
    route: {
      type: Boolean,
      default: false
    },
    modal: {
      type: Boolean,
      default: false
    },
    params: {
      type: Object,
      default: function () {
        return {}
      }
    },
    routeParams: {
      type: Object,
      default: function () {
        return {}
      }
    },
    exclude: null,
    search: null
  },
  data() {
    return {
      create: false,
      query: this.route ? {
        search: this.$route.query.search
      } : {
        search: this.search
      },
      grid: {
        collection: [],
        pagination: {
          itemsLength: 0,
          page: 1,
          pageCount: 1,
        },
        params: {}
      }
    }
  },
  watch: {
    $route() {
      if (this.$route) {
        this.query.search = this.$route.query.search
        this.query.page = this.$route.query.page
        this.load()
      }
    },
    params() {
      this.load();
    },
    routeParams() {
      if (this.route) {
        this.$router.push({ query: { ...this.query, ...this.routeParams } }).catch(() => {
          this.load()
        });
      } else {
        this.load()
      }
    }
  },
  computed: {
    highlight() {
      if (typeof this.query.search != 'string') {
        return ''
      }
      return this.query.search
    }
  },
  mounted() {
    this.load()
    this.$bus.$emit('focus', this.$refs.search)
  },
  methods: {
    clearFilter() {
      this.query.search = null
      this.filter()
    },
    onPage(page) {
      this.updateQuery({
        page: page
      })
    },
    filter() {
      this.updateQuery({
        search: this.query.search,
        page: 1
      })
    },
    updateQuery(params) {
      let query = { ...this.query, ...params };
      if (query.page === 1) {
        delete query.page
      }
      if (!query.search) {
        delete query.search
      }
      if (this.route) {
        this.$router.push({ query: query }).catch(() => {
          this.load()
        });
      } else {
        this.query = { search: null, page: 1, ...query };
        this.load()
      }

    },
    load() {
      mixturesApi.getMixturePlantProtections({
        ...this.query,
        ...this.params,
        ...this.routeParams
      }).then((resp) => {
        resp.data.collection.forEach(function (item) {
          item.edit = false
        })
        this.grid = resp.data
        this.$emit('loaded')

      });
    },
    isExcluded(row) {
      if (!this.exclude) {
        return false;
      }
      if (this.exclude === 'own' && row.id > 0) {
        return true;
      }
      return Array.isArray(this.exclude) && this.exclude.includes(row.id);
    },
    rowClick(row) {
      if (!this.isExcluded(row)) {
        this.$emit('rowclick', row)
      } else {
        this.$emit('excludedrowclick', row)
      }
    }
  },
}
</script>
<style scoped lang="scss">
div.name {
  width: 150px;
}

div.usage {
  width: 150px;
}

div.quantity {
  width: 100px;
}

div.composition {
  width: 300px;
}

div.av {
  width: 90px;
}
</style>
